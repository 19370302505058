import { useMsal } from "@azure/msal-react";
import { useCallback, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useHistory } from "react-router-dom";
import { ExpandIcon, LogoutIcon, SettingsIcon } from "../../components/icons";
import Avatar from "../../components/ui/avatar";
import Text from "../../components/ui/text";
import { useFeatureToggles } from "../../context/feature-toggles";
import { useUser } from "../../context/user";
import { Pages } from "../../enums/pages";
import { AvatarContainer, Container, List, ListItem, ListItemLabel, Name } from "./user-badge.styles";

const UserBadge = () => {
    const history = useHistory();
    const { instance } = useMsal();
    const [isOpen, setIsOpen] = useState(false);
    const { state: user } = useUser();
    const { isFeatureActive } = useFeatureToggles();

    const handleToggleMenu = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handleLogOut = useCallback(() => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }, [instance]);

    const handleOnClick = useCallback((event, callback) => {
        callback(event);
    }, []);

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: () => {
            setIsOpen(false);
        }
    });

    const handleOnSettingsClick = useCallback(() => {
        history.push(Pages.Settings);
    }, []);

    const handleChangePassword = useCallback(() => {
        instance.loginRedirect({
            authority: process.env.REACT_APP_MSAL_PASSWORD_CHANGE_AUTHORITY,
            scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE],
        });
    }, []);

    const getOptions = ({ settingsPage }) => {
        const options = [];

        if (settingsPage) {
            options.push({ label: 'Settings', icon: SettingsIcon, callback: handleOnSettingsClick });
        } else {
            options.push({ label: 'Change Password', icon: SettingsIcon, callback: handleChangePassword });
        }

        options.push({ label: 'Logout', icon: LogoutIcon, callback: handleLogOut });

        return options;
    }

    return <Container onClick={handleToggleMenu} ref={clickOutsideRef} data-audioclick>
        <AvatarContainer >
            <Avatar
                name={user.name}
                photo={user.photo} />
            <Name><Text>{user.name}</Text></Name>
            <ExpandIcon />
        </AvatarContainer>

        {isOpen && <List>
            {getOptions({
                settingsPage: isFeatureActive('SETTINGS_PAGE')
            }).map(({ label, icon: Icon, callback }) =>
                <ListItem data-audioclick onClick={(e) => handleOnClick(e, callback)}>
                    <Icon />
                    <ListItemLabel>
                        <Text>{label}</Text>
                    </ListItemLabel>
                </ListItem>)}
        </List>}
    </Container>;
}

export default UserBadge;
