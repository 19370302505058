import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ArrowDownIcon, InfoIcon, SupportIcon } from "../../components/icons";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page, { PageLoader } from "../../components/ui/page";
import Text from "../../components/ui/text";

import { data } from "./data";
import {
  Contact,
  Container,
  Content,
  FAQ,
  Header,
  IconWrapper,
  QuestionList,
  QuestionsContainer,
  QuestionTitle
} from "./support.styles";

import ContactSupportForm from "./contact/index";

const localeFn = (target) => import(`./locale/${target.toLowerCase()}.json`);

const Question = ({ title, content }) => {

  const [open, setOpen] = useState(false);

  return <div>
    <QuestionTitle open={open} onClick={() => setOpen(!open)} data-audioclick>
      {title}
      <ArrowDownIcon />
    </QuestionTitle>
    {open && <QuestionList>
      {content.map((content, index) => <li id={`question-content-${index}`}><Text variant="smallRegular">{content}</Text></li>)}
    </QuestionList>}
  </div>
}

const Head = ({ title, icon }) =>
  <Header>
    <IconWrapper>{icon}</IconWrapper>
    <Text>{title}</Text>
  </Header>

const Support = () => {
  const intl = useIntl();

  return <Page
    title={intl.formatMessage({ id: "support:title" })}
    description={intl.formatMessage({ id: "support:description" })}>
    <Container>
      <FAQ>
        <Head title={intl.formatMessage({ id: "support:faqs" })} icon={<InfoIcon />} />
        <Content>
          <QuestionsContainer>
            {data.map((question, index) => <Question key={`question-${index}`} {...question} />)}
          </QuestionsContainer>
        </Content>
      </FAQ>
      <Contact>
        <Head title={intl.formatMessage({ id: "support:contact" })} icon={<SupportIcon />} />
        <Content>
          <Text>
            <FormattedMessage id={'support:contact-form-message'} />
          </Text>
          <ContactSupportForm />
        </Content>
      </Contact>
    </Container>
  </Page>
}

const SupportPage = () =>
  <VRIntlProviderComponent
    localeFn={localeFn}
    id="support-page"
    fallback={<PageLoader />}>
    <Support />
  </VRIntlProviderComponent>


export default SupportPage;
