import { ReactNode, useCallback, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage, useIntl } from "react-intl";
import { DangerIcon, DotsIcon, EditIcon, TrashIcon } from "../../../../components/icons";
import ConfirmationDialog from "../../../../components/ui/confirmation-dialog";
import Styled from "../../../../components/ui/styled";
import { RequestStatus } from "../../../../types/requests";
import { Trainee } from "../../../../types/trainee";

import { Button, Container, Item, List } from "./options.styles";

type OptionProps = {
    name: string,
    icon: ReactNode,
}

const OPTIONS_LIST: OptionProps[] = [
    { name: 'deleteUser', icon: <TrashIcon /> },
    { name: 'changeRole', icon: <EditIcon /> }
];

const DeleteUserConfirmationDialog = ({
    trainee,
    onDismiss
}: {
    trainee: Trainee,
    onDismiss: () => void
}) => {
    const intl = useIntl();
    const [status, setStatus] = useState<RequestStatus>('IDLE');

    const handleOnClickConfirm = useCallback(() => {
        setStatus('PENDING');
    }, []);

    const Title = () => <>
        <Styled marginRight="1rem">
            <DangerIcon />
        </Styled>
        <FormattedMessage id="trainees-list:deleteUser_modal-title"></FormattedMessage>
    </>

    const isPending = status === 'PENDING';

    return <ConfirmationDialog
        onClickCancel={onDismiss}
        onClickConfirm={handleOnClickConfirm}
        title={<Title />}
        cancelLabel={intl.formatMessage({ id: 'trainees-list:deleteUser_modal-no' })}
        confirmLabel={intl.formatMessage({ id: 'trainees-list:deleteUser_modal-yes' })}
        disabled={isPending}>
        <FormattedMessage id="trainees-list:deleteUser_modal-content" />
    </ConfirmationDialog>
}

const Options = ({ trainee }: { trainee: Trainee }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [optionSelected, setOptionSelected] = useState<string | null>(null);

    const handleOnClick = useCallback(() => {
        setOpen(true);
    }, [open]);

    const handleOnClickOutside = useCallback(() => {
        setOpen(false);
    }, []);

    const clickOutsideRef = useDetectClickOutside({ onTriggered: handleOnClickOutside });

    return <>
        <Container ref={clickOutsideRef}>
            {/* <Button open={open} onClick={handleOnClick}>
                <DotsIcon />
            </Button> */}
            {open && <List>
                {OPTIONS_LIST.map(({ name, icon }) =>
                    <Item key={name} data-audioclick onClick={() => setOptionSelected(name)} >
                        {icon}
                        <Styled marginLeft="0.5rem">
                            <FormattedMessage id={`trainees-list:option_${name}`} defaultMessage={name} />
                        </Styled>
                    </Item>)}
            </List>}
        </Container>
        {optionSelected === 'deleteUser' &&
            <DeleteUserConfirmationDialog trainee={trainee} onDismiss={() => setOptionSelected(null)} />}
    </>
}

export default Options;